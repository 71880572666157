import Grid from '@material-ui/core/Grid'
import QrCode from './QrCode'

const QrCodeMapVisualiser = ({ clientRef, planName, planImage }) => (
  <Grid container style={{ paddingLeft: '80px' }}>
    <Grid item xs={6}>
      {planImage && planImage.src && (
        <img src={planImage.src} alt="map" width="30%" height="auto" />
      )}
    </Grid>
    <Grid item xs={6}>
      {clientRef && planName && (
        <QrCode clientRef={clientRef} planName={planName} size={220} level="Q" />
      )}
    </Grid>
  </Grid>
)

export default QrCodeMapVisualiser
