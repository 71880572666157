import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
  Filter,
  SimpleForm,
  Create,
  Edit,
  required,
  DateInput,
  BooleanInput,
} from 'react-admin'


const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Client" source="clientRef" reference="clients">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="zoneId" label="Zone Identifier" />
    <BooleanField source="isActive" label="Active?" />
  </Filter>
)

export const EmergencyList = (props) => (
  <List {...props} filters={<ListFilter />}>
    <Datagrid rowClick="edit">
      <BooleanField source="isActive" label="Active?" />
      <DateField source="startDate" label="Started" showTime locales="en-AU" />
      <DateField source="endDate" label="Ended" showTime locales="en-AU" />
      <TextField source="zoneId" label="Zone Identifier" />
      <ReferenceField label="Client" source="clientRef" reference="clients">
        <TextField source="name" label="Client Name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const EmergencyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="zoneId" label="Zone Identifier" validate={required()} />
      <ReferenceInput label="Client Name" source="clientRef" reference="clients" validate={required()} disabled>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="startDate" label="Started" locales="en-AU" disabled defaultValue={Date.now()} />
      <DateInput source="endDate" label="Ended" locales="en-AU" disabled defaultValue={new Date()} />
      <BooleanInput source="isActive" label="Active?" defaultValue={false} />
    </SimpleForm>
  </Edit>
)

export const EmergencyCreate = (props) => (
  <Create {...props} >
    <SimpleForm redirect="list">
      <TextInput source="zoneId" label="Zone Identifier" validate={required()} />
      <ReferenceInput label="Client Name" source="clientRef" reference="clients" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="startDate" label="Started" locales="en-AU" disabled defaultValue={Date.now()} />
      <BooleanInput source="isActive" label="Active?" defaultValue={true} disabled />
    </SimpleForm>
  </Create>
)
