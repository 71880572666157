import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FileField,
  SimpleForm,
  ReferenceInput,
  Edit,
  SelectInput,
  FileInput,
  Create,
  TextInput,
  Filter,
  required,
  regex,
  FormDataConsumer,
  Toolbar,
  SaveButton
} from 'react-admin'
import QrCodeMapVisualiser from './QrCodeMapVisualiser'

const INVALID_CHARS = /^[^#]+$/

// eslint-disable-next-line max-len
// From: https://github.com/firebase/firebase-js-sdk/blob/73a586c92afe3f39a844b2be86086fddb6877bb7/packages/firestore/src/util/misc.ts#L36
const newId = () =>{
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let autoId = ''
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return autoId
}

const MapCreateToolbar = props => {
  const transform = data => {
    const canvas = document.getElementsByTagName('canvas')[0]
    const pngUrl = canvas.toDataURL('image/png')
    return {
      ...data,
      qrcode: pngUrl
    }
  }
  return (
    <Toolbar {...props}>
      <SaveButton transform={transform} />
    </Toolbar>
  )
}

const MapFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Client" source="clientRef" reference="clients">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="zoneId" label="Zone Identifier" />
    <TextInput source="name" label="Plan Name" />
  </Filter>
)

export const MapList = (props) => (
  <List {...props} filters={<MapFilter />} title="Evacuation Plans">
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <TextField source="zoneId" label="Zone Identifier" />
      <ReferenceField label="Client" source="clientRef" reference="clients">
        <TextField source="name" label="Client Name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const MapEdit = (props) => (
  <Edit {...props} toolbar={<MapCreateToolbar />} title={`Evacuation Plan #${props?.id}`}>
    <SimpleForm>
      <ReferenceInput
        label="Client"
        source="clientRef"
        reference="clients"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer source="assemblyPointRef">
        {({ formData }) => {
          const { clientRef } = formData
          return (
            <ReferenceInput
              label="Assembly Point"
              source="assemblyPointRef"
              reference="assemblypoints"
              filter={{ clientRef }}
              validate={required()}
            >
              <SelectInput optionText="name" resettable={true} style={{ minWidth: '256px' }} />
            </ReferenceInput>
          )
        }}
      </FormDataConsumer>
      <TextField source="name" label="Name" validate={required()} />
      <TextInput source="zoneId" label="Zone Identifier" validate={required()} />
      <FileInput
        source="file"
        label="File"
        accept="image/*"
        validate={required()}
      >
        <FileField source="src" title="name" />
      </FileInput>
      <FormDataConsumer>
        {({ formData }) => (
          <QrCodeMapVisualiser clientRef={formData.clientRef} planName={formData.name} planImage={formData.file} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)

export const MapCreate = (props) => (
  <Create {...props} title="Create Evacuation Plan">
    <SimpleForm redirect="list" toolbar={<MapCreateToolbar />} initialValues={() => ({ id: newId() })}>
      <ReferenceInput
        label="Client"
        source="clientRef"
        reference="clients"
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer source="assemblyPointRef">
        {({ formData }) => {
          const { clientRef } = formData
          return (
            <ReferenceInput
              label="Assembly Point"
              source="assemblyPointRef"
              reference="assemblypoints"
              filter={{ clientRef }}
              validate={required()}
            >
              <SelectInput optionText="name" resettable={true} style={{ minWidth: '256px' }}  />
            </ReferenceInput>
          )
        }}
      </FormDataConsumer>
      <TextInput
        source="name"
        label="Name"
        validate={required()}
      />
      <TextInput
        source="zoneId"
        label="Zone Identifier"
        validate={[
          required(),
          regex(INVALID_CHARS, '# is a reserved character'),
        ]}
      />
      <FileInput
        source="file"
        label="File"
        accept="image/*"
        validate={required()}
      >
        <FileField source="src" title="name" />
      </FileInput>
      <FormDataConsumer>
        {({ formData }) => (
          <QrCodeMapVisualiser clientRef={formData.clientRef} planName={formData.name} planImage={formData.file} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)
