import React from 'react'
import { Admin, Resource } from 'react-admin'
import { FirebaseDataProvider, FirebaseAuthProvider } from 'react-admin-firebase'
import { createBrowserHistory as createHistory } from 'history'
import UserIcon from '@material-ui/icons/People'
import MapIcon from '@material-ui/icons/Map'
import FireplaceIcon from '@material-ui/icons/Fireplace'
import RoomIcon from '@material-ui/icons/Room'
import config from './config'
import CustomLayout from './components/CustomLayout'
import CustomLoginPage from './components/CustomLoginPage'
import { ClientList, ClientEdit, ClientCreate } from './components/Clients'
import { MapList, MapEdit, MapCreate } from './components/EvacuationPlans'
import { EmergencyList, EmergencyCreate, EmergencyEdit } from './components/Emergencies'
import { AssemblyPointList, AssemblyPointCreate, AssemblyPointEdit } from './components/AssemblyPoints'

const dataProvider = FirebaseDataProvider(config.firebaseConfig)
const authProvider = FirebaseAuthProvider(config.firebaseConfig)

authProvider.getIdentity = async () => {
  return Promise.resolve({
    id: config.adminUserId,
    fullName: 'Admin',
  })
}

const history = createHistory()

export default function App() {
  return (
    <Admin
      loginPage={CustomLoginPage}
      layout={CustomLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      history={history}
      disableTelemetry
    >
      <Resource
        name="clients"
        list={ClientList}
        edit={ClientEdit}
        create={ClientCreate}
        icon={UserIcon}
      />
      <Resource
        name="assemblypoints"
        list={AssemblyPointList}
        edit={AssemblyPointEdit}
        create={AssemblyPointCreate}
        icon={RoomIcon}
        options={{ label: 'Assembly Points' }}
      />
      <Resource
        name="maps"
        list={MapList}
        edit={MapEdit}
        create={MapCreate}
        icon={MapIcon}
        options={{ label: 'Evacuation Plans' }}
      />
      <Resource
        name="emergencies"
        edit={EmergencyEdit}
        create={EmergencyCreate}
        list={EmergencyList}
        icon={FireplaceIcon}
      />
    </Admin>
  )
}
