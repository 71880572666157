import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FileField,
  SimpleForm,
  ReferenceInput,
  Edit,
  SelectInput,
  FileInput,
  TextInput,
  Create,
  Filter,
  required,
  FormDataConsumer,
} from 'react-admin'

const MapVisualiser = () => (
  <FormDataConsumer>
    {({ formData }) => (
      (formData.file && formData.file.src && <img src={formData.file.src} alt="map" width="20%" height="auto" />)
    )}
  </FormDataConsumer>
)

const AssemblyPointFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Client" source="clientRef" reference="clients">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextField source="name" label="Name" />
  </Filter>
)

export const AssemblyPointList = (props) => (
  <List {...props} filters={<AssemblyPointFilter />} title="Assembly Points">
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <ReferenceField label="Client" source="clientRef" reference="clients">
        <TextField source="name" label="Client Name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const AssemblyPointEdit = (props) => (
  <Edit {...props} title={`Assembly Point #${props?.id}`}>
    <SimpleForm>
      <ReferenceInput label="Client Name" source="clientRef" reference="clients" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Name" validate={required()} />
      <FileInput source="file" label="File" accept="image/*" validate={required()}>
        <FileField source="src" title="name" />
      </FileInput>
      <MapVisualiser />
    </SimpleForm>
  </Edit>
)

export const AssemblyPointCreate = (props) => (
  <Create {...props} title="Create Assembly Point">
    <SimpleForm redirect="list">
      <ReferenceInput label="Client Name" source="clientRef" reference="clients" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Name" validate={required()} />
      <FileInput source="file" label="File" accept="image/*" validate={required()}>
        <FileField source="src" title="name" />
      </FileInput>
      <MapVisualiser />
    </SimpleForm>
  </Create>
)
