import React from 'react'
import {
  List,
  Datagrid,
  SimpleForm,
  TextField,
  Edit,
  Create,
  TextInput,
  required,
  number,
  Filter,
} from 'react-admin'

const ClientFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="phoneNumber" />
    <TextInput source="address.state" label="State" />
    <TextInput source="address.postcode" label="Postcode" />
  </Filter>
)

export const ClientList = (props) => (
  <List {...props} filters={<ClientFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Identifier" />
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <TextField source="address.state" label="State" />
      <TextField source="address.postcode" label="Postcode" />
    </Datagrid>
  </List>
)

export const ClientEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Identifier" source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="phoneNumber" validate={required()} />
      <TextInput source="address.street" label="Street" validate={required()} />
      <TextInput source="address.suburb" label="Suburb" validate={required()} />
      <TextInput source="address.state" label="State" validate={required()} />
      <TextInput
        source="address.postcode"
        label="Postcode"
        validate={[required(), number()]}
      />
    </SimpleForm>
  </Edit>
)

export const ClientCreate = (props) => (
  <Create {...props} >
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="phoneNumber" validate={required()} />
      <TextInput source="address.street" label="Street" validate={required()} />
      <TextInput source="address.suburb" label="Suburb" validate={required()} />
      <TextInput source="address.state" label="State" validate={required()} />
      <TextInput
        source="address.postcode"
        label="Postcode"
        validate={[required(), number()]}
      />
    </SimpleForm>
  </Create>
)
