import React from 'react'
import { Layout } from 'react-admin'
import { createMuiTheme } from '@material-ui/core/styles'
import CustomAppBar from './CustomAppBar'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3664ba'
    },
    secondary: {
      main: '#D5DCF9'
    },
    error: {
      main: '#bf0b0b'
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif'].join(','),
  }
})

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} theme={theme} />

export default CustomLayout