import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/logo-wide.png'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 25
  },
  spacer: {
    flex: 1,
  },
  logo: {
    width: '150px',
    height: 'auto',
    marginLeft: -25
  },
})

const CustomAppBar = props => {
  const classes = useStyles()
  return (
    <AppBar {...props} color="secondary" >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
      <span className={classes.spacer} />
    </AppBar >
  )
}

export default CustomAppBar