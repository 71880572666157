import React from 'react'
import QRCode from 'qrcode.react'
import logo from '../images/logo.png'
import DownloadButton from './DownloadButton'

const defaultSettings = {
  src: logo,
  x: null,
  y: null,
  height: 70,
  width: 70,
  excavate: true,
}

const QrCode = ({ clientRef, planName, imageSettings = defaultSettings, ...rest }) => {
  const getImageUrl = () => {
    const canvas = document.getElementsByTagName('canvas')[0]
    const pngUrl = canvas.toDataURL('image/png')
    return pngUrl
  }

  const createDummyLink = (url, filename) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadFile = (filename) => {
    const url = getImageUrl()
    createDummyLink(url, filename)
  }

  return (
    <>
      {/* <QRCode value={`${clientRef}#${planName}`} renderAs="canvas" imageSettings={imageSettings}  {...rest} /> */}
      <QRCode value={`${clientRef}#${planName}`} renderAs="canvas" {...rest} />
      <br />
      <DownloadButton onClick={() => downloadFile(planName)} />
    </>
  )
}

export default QrCode
